import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VueHtmlToPaper from 'vue-html-to-paper'
const options = {
  name: '_blank',
  autoClose: true,
  styles: [
    'https://admin.fridasenbici.com/styles.scss',
    'https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css'
  ]
}
Vue.use(VueHtmlToPaper, options)

Vue.config.productionTip = false

// Update hosting and github actions account
const apiToken = "c10added5d47e3e96224aa4b279fd738497bd760" // c10added5d47e3e96224aa4b279fd738497bd760
const baseURL = "https://fridas-api.herokuapp.com" // https://fridas-api.herokuapp.com

// Libreria bootstrap
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

// Libreria fontawesome
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

// Firebase V9
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyBgz2Zu0XvwoGJ4Gk4xTVmt178x7VRc91E",
  authDomain: "fridas-17d5b.firebaseapp.com",
  databaseURL: "https://fridas-17d5b.firebaseio.com",
  projectId: "fridas-17d5b",
  storageBucket: "fridas-17d5b.appspot.com",
  messagingSenderId: "741448983902",
  appId: "1:741448983902:web:0783d08e2c8bdab37c1bcf"
}
// Initialize Firebase
const app = initializeApp(firebaseConfig)

const storage = getStorage(app)

Vue.prototype.$fstorage = storage
Vue.prototype.$apiToken = apiToken
Vue.prototype.$baseURL = baseURL

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})  
