<template>
    <div>
        <!-- LOGIN -->
        <div id="login">
          <section id="login">
            <div id="ventanalog">
              <div class="logo-login">
                <img src="@/assets/logo.png" class="img-responsive">
              </div>
              <div class="box-form">
                <form @submit.prevent="login">
                  <input type="hidden" name="csrfmiddlewaretoken" value="H3gEBgEwJSIu1aiBQkijcWMXiOFW7YBwpIJiQGIyC7oj6Uz6pOtk3qsNZZpLxFuy">
                  <div class="form-group">
                    <label for="nombre">Nombre:</label>
                    <input v-model="usuario" type="text" class="form-control" id="user" name="username">
                  </div>
                  <div class="form-group">
                    <label for="pass">Contraseña:</label>
                    <input v-model="pass" type="password" class="form-control" id="pwd" name="password">
                  </div>
                  <button class="button-style btn-block mt-4" type="submit">Ingresar</button>
                </form>
              </div>
            </div>
          </section>
        </div>
        <!-- END LOGIN -->
    </div>
</template>

<script>
    import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
    import Swal from 'sweetalert2'

    export default {
        data() {
            return {
                titulo: "Login",
                usuario : "",
                pass: "",
            }
        },
        methods: {
            login() {
                const auth = getAuth();
                signInWithEmailAndPassword(auth, this.usuario + '@fridas.com', this.pass)
                .then((user) => {
                    this.$router.replace('/')
                },(error) => {
                    console.log(error)
                    var msj = error.code.split('/')
                    if(msj[1] == "network-request-failed")
                        Swal.fire('Error', "Verifique Conexión a Internet", 'error')
                    else
                      Swal.fire('Error', "Usuario/Contraseña Incorrectos", 'error')
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
#login {
  height: 100vh;
  background: linear-gradient(rgba(255, 49, 108, 0.85), rgba(83, 40, 122, 0.93)), url('../assets/logo.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative; }
  #login .logo-login {
    width: 350px;
    height: 350px;
    margin: 0 auto;
    display: flex; }
    #login .logo-login img {
      width: 100%;
      object-fit: contain; }
  #login .box-form {
    width: 500px;
    margin: 0 auto; }
    #login .box-form label {
      color: #fff;
      font-family: "Oswald-Bold"; }


.button-style {
  color: #fff !important;
  width: 200px;
  background-image: linear-gradient(to right, #a10ba9 0%, #ff306c 51%, #a10ba9 100%);
  flex: 1 1 auto;
  padding: 10px 30px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2); */
  box-shadow: 0 0 20px #eee;
  border-radius: 10px; }
  .button-style:hover {
    background-position: right center; }

.button-style-diss {
  cursor: no-drop;
  color: gray !important; }
</style>