import Vue from 'vue'
import VueRouter from 'vue-router'
import { getAuth } from "firebase/auth";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Inicio',
    component: () => import('../views/Inicio.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  getAuth().onAuthStateChanged(user => {
    if (!user && to.path !== '/login') {
      next('/login')
    } else {
      next()
    }
  })
})

export default router
